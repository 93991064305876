@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600&display=swap');
a {
    text-decoration: none;
    color: #3D3D3D;
}
body {
    font-family: "Nunito";
    color: #3D3D3D;
    margin: 0;
    font-weight: 500;
}
dl, ol, ul {
    margin-top: 0;
    margin-bottom: 0;
}
.socialTab {
    height: 30px;
    width: 100%;
    /* background: linear-gradient(to right, #73c3e5 0%, #89cfca 100%); */
    background: linear-gradient(to right, rgba(112, 197, 186, 1) 0%, rgba(100, 197, 233, 1) 48%, rgba(116, 152, 207, 1) 100%);
}
.topArea {
    background-color: white;
    box-shadow: 0 0px 15px rgba(0, 0, 0, 0.2);
    z-index: 100;
    position: relative;
    padding: 20px 0;
}
/* .inner {
    clear: both;
    max-width: 1450px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    height: fit-content;
    padding: 0px 80px;
} */
.center {
    text-align: center;
}
.mainnav>ul>li {
    list-style-type: none;
    list-style: none;
    display: inline-block;
    font-size: 19px;
}
.mainnav>ul a {
    width: calc(100% - 0px);
    height: calc(100% - 0px);
    padding: 15px 15px;
    margin: 0px 1px;
    cursor: pointer;
}
a.nav-item {
    text-decoration: none;
}
.logo {
    color: white;
    font-size: 30px;
    border-radius: 10px;
    width: 40px;
    height: 40px;
    background-color: rgba(112, 197, 186, 1);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
}
.logo-txt {
    margin: 0;
    line-height: 0px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-left: 12px;
}

/* .row {
    display: flex;
    flex-wrap: wrap;
} */
.contact-btn,
.btn1,
.btn2 {
    color: white;
    border-radius: 10px;
    width: 80px;
    height: 40px;
    background-color: rgba(116, 152, 207, 1);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 600;
}
.contact-btn:hover {
    background-color: rgba(112, 197, 186, 1);
    /* box-shadow: 0 0px 10px rgba(0, 0, 0, 0.); */
}
.btn1,
.btn2 {
    width: 125px;
    display: inline-flex;
    justify-content: center;
    background-color: rgba(112, 197, 186, 1);
    border: 0;
}
.btn1:hover {
    background-color: rgba(116, 152, 207, 1);
}
.contact-btn > a {
    color: white;
}
.btn2 {
    background-color: white;
    border: solid 1.5px rgba(112, 197, 186, 1);
    color: rgba(112, 197, 186, 1);
    font-weight: 600;
}
.btn2:hover {
    background-color: rgba(116, 152, 207, 1);
    color: white;
    border: inherit;
}
/* .col-md-3,
.col-md-6 {
    display: flex;
    flex: 0 0 25%;
    max-width: 25%;
}
.col-md-6,
.nav-area {
    flex: 0 0 50%;
    max-width: 50%;
} */
.contact-area {
    display: flex;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
}
.nav-area {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}
.logo-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.logo-txt > div > h3,
.logo-txt > div > h5 {
    margin: 0;
}
.logo-txt span {
    font-size: 13.5px;
    line-height: 1.2;
}
.logo-txt h3 {
    font-size: 20px;
}
.mainnav > ul {
    padding-inline-start: 0;
}
.profile-circle {
    position: absolute;
    width: 407px;
    height: 407px;
    border-radius: 100%;
    background: linear-gradient(to right, rgba(112, 197, 186, 1) 0%, rgba(100, 197, 233, 1) 48%, rgba(116, 152, 207, 1) 100%);
}
.profile-circle::before {
    content: "";
    width: 320px;
    height: 320px;
    border-radius: 100%;
    background-color: white;
    display: block;
    position: absolute;
    margin: 44px;
}
.banner-area {
    padding-top: 120px;
}
.my-photo-circle {
    position: relative;
    width: 320px;
    height: 498px;
    z-index: 1;
    margin: 45px;
    top: -179px;
    right: -1.5px;
}

button:focus {
    outline: none !important;
}
/* .line {
    display: block;
    width: 70px;
    height: 5px;
    background-color: rgba(112, 197, 186, 1);
} */
.intro2 {
    font-size: 40px;
    font-weight: bold;
    display: block;
    margin-top: 10px;
}
.intro1 {
    font-size: 22px;
    color: rgba(112, 197, 186, 1);
    display: block;
    margin-top: 30px;
    font-weight: 600;
}
.banner-btn-area {
    display: flex;
    margin-top: 30px;
}
.margin-r-20 {
    margin-right: 20px ;
}
.margin-tb40 {
    margin: 40px 0;
} 
.margin-b20 {
    margin-bottom: 20px;
} 
.block-center {
    margin: 0 auto;
}
.foot {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 100%;
    background: linear-gradient(to right, rgba(112, 197, 186, 1) 0%, rgba(100, 197, 233, 1) 48%, rgba(116, 152, 207, 1) 100%);
    color: white;
    height: 30px;
    /* position: fixed; */
    bottom: 0;
    line-height: 2;
    z-index: -1;
    overflow: hidden;
}
.color1 > a:hover,
.color1 .active {
  color: rgba(112, 197, 186, 1)  !important
} 

.color2 > a:hover,
.color2 .active {
  color: #6bc4ca !important;
}

.color3 > a:hover,
.color3 .active {
  color: #67c4da !important;
}

.color4 > a:hover,
.color4 .active {
  color: rgba(100, 197, 233, 1) !important;
}

.color5 > a:hover,
.color5 .active {
  color: #6ab4df !important;
}

.banner-profile-pic-area {
    display: flex;
    justify-content: flex-end;
    max-height: 540px;
}
.mobileMenuGo {
    background-color: #efefef;
    color: #3d3d3d;
    cursor: pointer !important;
    padding: 6px 0px;
    width: 100%;
    border: none;
    text-align: center;
    outline: none;
    font-size: 20px;
    margin: 2px 0px;
    padding-left: 10px;
    padding-right: 10px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.mobileDropdown {
    /* position: absolute; */
    width: 100%;
    padding-top: 20px;
}
.color1.active a {
    text-decoration: none;
    color: rgba(112, 197, 186, 1);
}
.color2.active a {
    text-decoration: none;
    color: #6bc4ca;
}
.color3.active a {
    text-decoration: none;
    color: #67c4da;
}
.color4.active a {
    text-decoration: none;
    color: rgba(100, 197, 233, 1);
}
.color5.active a {
    text-decoration: none;
    color: #6ab4df;
}
.bg-gray {
    background-color: #eaedf2;
}
.line2,
.line3 {
    height: 2px;
    background: rgba(112, 197, 186, 1);
    width: 50px;
    display: block;
}
.line3 {
    background: rgba(100, 197, 233, 1);
}
.section {
    padding: 40px 0 0 0;
}
.about h2 {
    font-weight: bold;
}
.padT10 {
    padding-top: 10px;
}
.padT20 {
    padding-top: 20px;
}
.padB20 {
    padding-bottom: 20px;
}
.about-img {
    border: 14px solid #ffffff;
    border-radius: 5px;
    position: relative;
}
.box-shadow {
    -webkit-box-shadow: 0px 3px 10px 0px rgba(38, 59, 94, 0.1);
    box-shadow: 0px 3px 10px 0px rgba(38, 59, 94, 0.1);
}
.w-100 {
    width: 100%;
}
.h-100vh {
    height: 100vh;
}
.margin15-b {
    margin-bottom: 15px;
}
.margin40-b {
    margin-bottom: 40px;
}
.margin40-tb {
    margin: 40px 0;
}
.about-me {
    padding-left: 40px;
}
.about-me h5 {
    font-weight: bold;
    background: linear-gradient(to right, rgba(112, 197, 186, 1) 0%, rgba(100, 197, 233, 1) 48%, rgba(116, 152, 207, 1) 100%);
    -webkit-text-fill-color: transparent;
    background-clip: text;
}
.dark-gray {
    color: #212529;
}
.full-screen90 {
    min-height: 90vh;
}
.contact-btn.active {
    background-color: rgba(112, 197, 186, 1);
}
.contact-form {
    border-radius: 5px;
    background: white;
    padding: 5%;
}
.contact-form h4 {
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 20px;
}
.contact-form .form-group {
    position: relative;
    margin-bottom: 20px;
}
.contact-form .form-group .form-control {
    border: none;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
    padding-left: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.effect-line ~ .focus-border {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 2px;
    background: linear-gradient(to right, rgba(112, 197, 186, 1) 0%, rgba(100, 197, 233, 1) 48%, rgba(116, 152, 207, 1) 100%);
    transition: 0.4s;
}
  .effect-line:focus ~ .focus-border {
    width: 100%;
    transition: 0.4s;
    left: 0;
}
.contact-me .icon,
.contact-me .text {
    display: inline-flex;
}
.contact-me .icon {
    font-size: 20px;
    line-height: 10px;
    vertical-align: bottom;
    color: rgba(112, 197, 186, 1);
    padding-right: 10px;
}
.content {
    /* overflow: hidden; */
    position: relative;
}
.content .content-overlay {
    background: rgba(0,0,0,0.7);
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    bottom: 0;
    right: 0;
    border-right: 12px solid #eaedf2;
    border-left: 12px solid #eaedf2;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}
  
.content:hover .content-overlay{
    opacity: 1;
}
.content-details {
    position: absolute;
    text-align: center;
    padding-left: 2em;
    padding-right: 2em;
    width: 100%;
    top: 50%;
    left: 50%;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}
.content:hover .content-details{
    top: 50%;
    left: 50%;
    opacity: 1;
}
.content-details h3{
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.15em;
    margin-bottom: 0.5em;
    text-transform: uppercase;
}
.content-details p{
    color: #fff;
    font-size: 0.8em;
}
.fadeIn-bottom{
    top: 80%;
}




/* media screen */


@media screen and (max-width: 1279px){
    .inner {
        padding: 0 20px;
    }
}
@media screen and (max-width: 1050px){
    .foot {
        position: sticky;
    }
}
@media (min-width: 1024px) {
    .mobileDropdown {
        display: none;
    }
    .col-lg-3 {
        flex: 0 0 auto;
        width: 25%;
    }
    .mobile-menu {
        display: none;
    }
}
@media screen and (max-width: 1023px){
    .mainnav,
    .nav-area, 
    .contact-btn{
        display: none;
    }
    .intro1 {
        margin: inherit;
    }
    .mobile-menu {
        display: block;
    }
    .mobile-menu-button {
        width: 40px;
        height: 40px;
        cursor: pointer;
        display: flex;
        background-color: #67c4da;
        border-radius: 10px;
        flex-direction: row;
        align-content: center;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 26px;
    }
    .line1 {
        background-color: white;
        width: 100%;
        border-radius: 3px;
        height: 2px;
        padding-top: 0px;
        display: block;
    }

    .banner-area > .col-lg-6 {
        text-align: center;
    }
    .banner-area {
        padding-top: 50px;
    }
    .banner-btn-area,
    .banner-profile-pic-area {
        justify-content: center;
        display: flex;
    }
    .banner-profile-pic-area {
        margin-top: 130px;
    }
    .my-photo-circle {
        right: 0px;
    }
    .col-lg-3 {
        width: 50%;
    }
}

/* tab menu start */

.tabbar {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-around;
  }
  
  .tab {
    border: 0;
    border-bottom: 2px solid #ccc;
    color: #aaa;
    padding: 1rem;
    transition: color ease-in-out 300ms;
    width: 100%;
    font-weight: 500;
    background-color: transparent;
  }
  
  .tab:hover {
    color: inherit;
    cursor: pointer;
  }
  
  .tab1.active {
    border-bottom-color: rgba(112, 197, 186, 1);
    color: inherit;
  }
  .tab2.active {
    border-bottom-color: rgb(106, 197, 214, 1);
    color: inherit;
  }
  .tab3.active {
    border-bottom-color: rgb(106, 182, 224, 1);
    color: inherit;
  }
  .tab4.active {
    border-bottom-color: rgba(116, 152, 207, 1);
    color: inherit;
  }
  .tab .active:hover {
    cursor: default;
  }
  
  .tabpanel {
    padding: 1em 0;
  }
  
  .tabpanel.active {
    animation-duration: 300ms;
    animation-name: panelswitch;
    animation-timing-function: ease-in;
    display: block;
  }
  
  /* Animations */
  
  @keyframes panelswitch {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
/* tab menu end */

.carousel-control-next,
.carousel-control-prev {
    width: 8% !important;
}



@media (max-width: 991px) {
    .about-me {
        padding-left: 0;
    }
}

@media screen and (max-width: 530px){
    .foot {
        font-size: 12px;
        line-height: 2.5;
    }
}
@media screen and (max-width: 420px){
    .profile-circle {
        width: 347px;
        height: 347px;
        top: auto;
    }
    .my-photo-circle {
        width: 275px;
        height: auto;
        top: -162px;
    }
    .profile-circle::before {
        width: 275px;
        height: 275px;
        margin: 36.5px;
    }
}
@media screen and (max-width: 411px){
    /* .foot {
        position: fixed;
    } */
    .banner-btn-area {
        margin-top: 10px;
    }
    .profile-circle {
        width: 317px;
        height: 317px;
        top: auto;
    }
    .profile-circle::before {
        width: 240px;
        height: 240px;
        margin: 39px;
    }
    .my-photo-circle {
        width: 240px;
        height: auto;
        top: -140px;
    }
}
@media screen and (max-width: 350px){
    .profile-circle {
        width: 257px;
        height: 257px;
        top: auto;
    }
    .my-photo-circle {
        width: 190px;
        height: auto;
        top: -117px;
    
    }
    .profile-circle::before {
        width: 190px;
        height: 190px;
        margin: 34px;
    }
    .foot {
        font-size: 10px;
        line-height: 2.7;
    }
    .col-lg-3 {
        flex: inherit;
        width: inherit;
    }
    .col-5 {
        flex: 0 0 auto;
        width: 41.66666667%;
    }
    .col-7 {
        flex: 0 0 auto;
        width: 58.33333333%;
    }    
}
@media (min-width: 1200px) {
.container {
    max-width: 1200px;
    }
}
.pdf-view {
    height: 100%;
}
.CVcontainer {
    max-width: 70vh;
    margin: auto;
    min-height: 70vh;
}
.expanded {
    max-width: 150vh;
}
.CVarea {
    height: auto;
    min-height: 100vh;
    background: black;
}
.react-pdf__Page__textContent {
    display: none !important;
}
.swal2-confirm {
    background-color: #6bc4ca !important;
}
.swal2-confirm:focus {
    box-shadow: none !important;
}

  